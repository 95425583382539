import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Field } from 'react-final-form'
import { Autocomplete, TextField } from '../../Components/Fields'
import trabalhadoresRepository from '../repositories/trabalhadoresRepository'
import cargosRepository from '../repositories/cargosRepository'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'
import { useParams } from 'react-router-dom'

const TrabalhadoresForm = (props) => {
  const params = useParams();
  const [cargos, setCargos] = useState();

  useEffect(() => {
    ;(async () => {
      const response = await cargosRepository.list({
        paginate: { [`$limit`]: 999 },
        query: { clienteId: params.id }
      });

      if (response.ok) {
        setCargos(response.data.map(item => ({
          label: item.nome,
          value: item.id
        })))
      }
    })()

  }, [params.id]);

  const entityManager = useEntityManager({
    repository: trabalhadoresRepository,
    relationKey: 'clienteId',
    goBackAfterSave: true,
  });
  
  const schema = yup.object().shape({
    nome: yup.string().required('Obrigatório')
  })
  
  const validate = yupValidation(schema);

  return <CrudForm
    {...props}
    {...entityManager}
    withPaper
    validate={validate}
  >
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="nome"
          label="Nome"
          component={TextField}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field
          fullWidth
          name="matricula"
          label="Matricula"
          component={TextField}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Field
          fullWidth
          name="cargoId"
          label="Cargo"
          component={Autocomplete}
          options={cargos}
        />
      </Grid>
    </Grid>
  </CrudForm>
}

export default TrabalhadoresForm;
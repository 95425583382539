import { restApi } from '../../services/api'
import { createBasicRepository as createRepository } from '../../Components/Repository'
import { paginate } from '../../lib/feathers'

const riscosRepository = createRepository({
  path: 'riscos',
  restApi,
  queryTransform: {
    ...paginate
  },
})

export default riscosRepository

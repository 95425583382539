import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import PickFile from '../../Components/Common/PickFile';
import api from '../../services/api';
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import { Snackbar } from '../../Components/Common';

const StatusDialog = {
    default: 'default',
    running: 'running',
    success: 'success',
    error: 'error'
}

const useStyles = makeStyles(theme => ({
    successButton: {
        color: green[500],
      },
    errorButton: {
        color: red[500],
    },
}));

export default function TrabalhadoresImportacaoDialog(props) {

    const [statusDialog, setStatusDialog] = useState(StatusDialog.default);
    const [errorMessage, setErrorMessage] = useState();

    const classes = useStyles();

    const { open, handleClose, clienteId } = props

    const LinkDownload = React.forwardRef((props, ref) => <Link ref={ref} {...props} to={`${process.env.PUBLIC_URL}/modelo-importacao-trabalhadores.xlsx`} target="_blank" download></Link>);

    const  importarPlanilha = async (data) => {

        const formData = new FormData()
        formData.append(`file`, data[0].file)
        formData.append(`clienteId`, clienteId);

        setStatusDialog(StatusDialog.running);
        const result = await api.trabalhadoresImportacao(formData);    
        if (result.ok) {
            setStatusDialog(StatusDialog.success);

            setTimeout(() => {
                handleClose();
            }, 2000);
            
        } else {
            setStatusDialog(StatusDialog.error);

            setErrorMessage(result.data.message);

            setTimeout(() => {
                setStatusDialog(StatusDialog.default);
            }, 2000);
        }
    }

    const importButtonClass = () => {
        if (statusDialog === StatusDialog.success) {
            return classes.successButton;
        }

        if (statusDialog === StatusDialog.error){
            return classes.errorButton;
        }
    }

    const importButtonText = () => {
        if (statusDialog === StatusDialog.running){
            return "Importando..."
        }
        
        if (statusDialog === StatusDialog.success) {
            return "Successo"
        }
        if (statusDialog === StatusDialog.error) {
            return "Ocorreu um erro"
        }
        
        return "Importar Planilha";
    }

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Importação Trabalhadores'}</DialogTitle>

                <img style={{ height: 200, width: 200, alignSelf: 'center' }}
                    alt="website logo" src={`${process.env.PUBLIC_URL}/upload.svg`}></img>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{'Utilize essa funcionalidade para importar os trabalhadores via planilha. Baixe o arquivo modelo, altere com os dados necessários e faça upload da planilha! Fique atento as seguintes instruções:'}</DialogContentText>
                    <DialogContentText id="alert-dialog-description">{
                    <div>
                            <br/> 
                            * Não exclua e nem altere a primeira linha da planilha
                            <br/> 
                            * Mantenha o arquivo no formato XLSX
                            <br/> 
                            * Preencha todas as colunas do arquivo
                            <br/> 
                    </div>}</DialogContentText>
                </DialogContent>




                <DialogActions>
                    <Button onClick={handleClose} color={"default"} autoFocus>
                        {'Fechar'}
                    </Button>


                    {statusDialog === StatusDialog.default &&
                        <Button component={LinkDownload} color={"default"} autoFocus>
                            {'Download Modelo Importação'}
                        </Button>
                    }


                    <PickFile
                        accept=".xlsx"
                        disabled={statusDialog !== StatusDialog.default}
                        id={`pick-file`}
                        onChange={importarPlanilha}
                    >
                        <Button 
                            htmlFor={`pick-file`}
                            component="label"
                            type="button"
                            className={importButtonClass()}
                            color="primary"
                             autoFocus>
                            {importButtonText()}
                        </Button>
                    </PickFile>
                </DialogActions>

                <Snackbar
                    onClose={() => setErrorMessage(null)}
                    message={errorMessage}/>

            </Dialog>

        </div>
    )
}

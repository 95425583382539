import React, { useEffect, useState } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import moment from "moment";
import dadosLaudosGesRepository from "../repositories/dadosLaudosGesRepository";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  info: {
    fontSize: 12,
    color: theme.palette.secondary.main,
    marginLeft: 8,
    marginRight: 16,
  }
}));

const DadosLaudosGes = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [dadosLaudos, setDadosLaudos] = useState();
  
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true);
        const response = await dadosLaudosGesRepository.list({
          query: {
            grupoExposicaoId: props.grupoExposicaoId
          },
          paginate: { [`$limit`]: 1 },
        });

        if (response.ok && response.data?.length) {
          setDadosLaudos(response.data[0])
        }
      } finally {
        setIsLoading(false);
      }
    })()

  }, [props.grupoExposicaoId]);

  return <>
    <Grid item xs={12}>
      <span className={classes.label}>{"Dados Laudos"}</span>
    </Grid>
    <Grid xs={12}>
      {isLoading ? 
        '...' : 
        dadosLaudos ?
          <>
            <span className={classes.info}>{`Atualizado por último em ${moment(dadosLaudos.updatedAt).format('DD/MM/YYYY HH:mm')}`}</span>
            <Button color='primary' size='small' variant='text' onClick={() => history.push(`${props.grupoExposicaoId}/dados-laudos/${dadosLaudos.id}`)}>
              {"Editar"}
            </Button>
          </> :
          <>
            <span className={classes.info}>{`Não informados`}</span>
            <Button color='primary' size='small' variant='text' onClick={() => history.push(`${props.grupoExposicaoId}/dados-laudos/new`)}>
              {"Adicionar"}
            </Button>
          </>}
    </Grid>
  
  </>
}

export default DadosLaudosGes;
import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Field } from 'react-final-form'
import { TextField } from '../../Components/Fields'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import empresasRepository from '../repositories/empresasRepository'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'

const schema = yup.object().shape({
  nome: yup.string().required('Obrigatório'),
})

const validate = yupValidation(schema)

const EmpresaForm = props => {

  const entityManager = useEntityManager({
    repository: empresasRepository,
    handleError: data => data.message,
    goBackAfterSave: true
  });
  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        validate={validate}
        withPaper
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="nome"
              label="Nome"
              component={TextField}
            />
          </Grid>  
        </Grid>
      </CrudForm>
    </>
  )
}

export default EmpresaForm

import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Field } from 'react-final-form'
import { Autocomplete, TextField } from '../../Components/Fields'
import { CrudForm, ImagePicker } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'
import { validateCNPJ } from "validations-br";
import CNPJField from '../../Components/Fields/CNPJField'
import withoutLogo from '../../img/no_logo.png'
import { makeStyles } from '@material-ui/core'
import brasilApi from '../../services/brasilApi'
import Fetching from '../../Components/Common/Fetching'
import { setValue } from '../../utils/utils'
import clientesRepository from '../repositories/clientesRepository'
import engenheirosRepository from '../repositories/engenheirosRepository'
import FilePicker from '../../Components/Common/FilePicker'
import { useHistory } from 'react-router-dom'
import DescriptionIcon from '@material-ui/icons/Description'
import { restApi } from '../../services/api'
import { saveAs } from 'file-saver'

const useStyles = makeStyles(theme => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(1)
  },
  mustSaveContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  mustSaveText: {
    fontSize: 14,
    color: theme.palette.secondary.main
  },
  mustSaveLink: {
    marginLeft: 4,
    fontSize: 14,
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  downloadButton: {
    width: 250,
    display: 'flex',
    justifyContent: 'space-around'
  }
}))

const schema = yup.object().shape({
  cnpj: yup.string().required('Obrigatório').test("is-cnpj", "CNPJ inválido", (value) => validateCNPJ(value)),
  nome: yup.string().required('Obrigatório'),
})

const validate = yupValidation(schema)

const ClienteForm = props => {

  const entityManager = useEntityManager({
    repository: clientesRepository,
    handleError: data => data.message
  });  


  const [fetchingCNPJ, setFetchingCNPJ] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [engenheiros, setEngenheiros] = useState(false);

  useEffect(() => {
    ;(async () => {
      const response = await engenheirosRepository.list({
        paginate: { [`$limit`]: 999 },
      });

      if (response.ok) {
        setEngenheiros(response.data.map(item => ({
          label: item.nome,
          value: item.id
        })))
      }
    })()

  }, []);

  const onChangeCnpj = (cnpj, form) => {
    if (cnpj) {
      const numeroCnpj = cnpj.replace(/\D/g,'');
      if (numeroCnpj.length === 14) {
        setFetchingCNPJ(true);
        brasilApi.getCnpj(numeroCnpj).then(response => {
          if (response.ok) {
            const { data } = response;
            form.mutators.setValue("cnpj", cnpj);
            form.mutators.setValue("nome", data.razao_social);
            form.mutators.setValue("endereco", `${data.descricao_tipo_logradouro || ''} ${data.logradouro || ''}, ${data.numero || ''}, ${data.complemento || ''}, ${data.bairro || ''}, ${data.municipio || ''}, ${data.uf || ''}`);
          }
          form.mutators.setValue("cnpj", cnpj);
          setFetchingCNPJ(false);
        });
      }    
    }
  }    

  const downloadPDF = async () => {
    setDownloading(true);
    const response = await restApi.get(`pgr-report/${entityManager.id}`, {}, { responseType: `blob` })
    try {
      if (response.ok && response.data) {
        saveAs(response.data, `${entityManager.item?.nome || "PGR Cliente"}.pdf`)
      } else {
        entityManager.setError("Erro ao gerar relatório");
      }
    } catch (e) {
      entityManager.setError("Erro ao gerar relatório");
    } finally {
      setDownloading(false);
    }
  }

  const classes = useStyles();
  const history = useHistory();  

  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        validate={validate}
        withPaper
        formProps={{
          mutators: { setValue }
        }}
        customActions={[{
          label: 'Baixar Relatório PGR',
          onClick: downloadPDF,
          icon: <DescriptionIcon/>,
          loading: downloading,
          disabled: (form) => entityManager.isNew || form.getState().dirty,
          props: {
            color: 'secondary',
            className: classes.downloadButton
          }
        }]}
      >
        {({ form }) => {
          
          const isDirty = entityManager.isNew || form.getState().dirty;

          return <Grid container spacing={2}>
            <Grid item xs={12} sm={3} md={2} xl={1} className={classes.logoContainer} >
              <Field
                name="logoUrl"
                defaultImage={withoutLogo}
                size={100}
                component={ImagePicker}
                label="Logo"
              />
            </Grid>
            <Grid item xs={12} sm={9} md={10} xl={11} >
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Field
                    fullWidth
                    name="cnpj"
                    label="CNPJ"
                    component={CNPJField}
                    customOnChange={(event) => onChangeCnpj(event.target.value, form)}
                  />
                </Grid>
                <Fetching isFetching={fetchingCNPJ}>
                  <>
                    <Grid item sm={6} xs={12}>
                      <Field
                        fullWidth
                        name="nome"
                        label="Nome"
                        component={TextField}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        fullWidth
                        name="endereco"
                        label="Endereço"
                        component={TextField}
                      />
                    </Grid>
                  </>
                </Fetching>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>            
              <Field
                fullWidth
                name="unidade"
                label="Unidade"
                component={TextField}
              />
            </Grid>
            <Grid item xs={12} md={6}>            
              <Field
                fullWidth
                name="engenheiroLtcatId"
                label="Engenheiro responsável"
                component={Autocomplete}
                options={engenheiros}
              />
            </Grid>

            <Grid item xs={12} sm={6}>            
              <Field
                name="documentoBaseUrl"
                component={FilePicker}
                label="Documento Base PGR"
                accept='.pdf'
                filename="documento_base"
              />
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <Field
                name="artUrl"
                component={FilePicker}
                label="ART"
                accept='application/pdf'
                filename="art.pdf"
              />
            </Grid> 

            
            {isDirty && <div className={classes.mustSaveContainer}>
              <span className={classes.mustSaveText}>{"Para adicionar e editar dados do cliente, "}</span>
              <span className={classes.mustSaveLink} onClick={() => form.submit()}>{"salve as alterações"}</span>
            </div>}
            
            {!isDirty && <>
              <Grid item xs={12}>
                <div onClick={() => history.push(`/clientes/${entityManager.id}/trabalhadores`)}
                  className={classes.title}
                >
                  {`TRABALHADORES >>`}
                </div>            
              </Grid>
              <Grid item xs={12}>
                <div onClick={() => history.push(`/clientes/${entityManager.id}/locais-trabalho`)}
                  className={classes.title}
                >
                  {`LOCAIS DE TRABALHO >>`}
                </div>            
              </Grid>
              <Grid item xs={12}>
                <div onClick={() => history.push(`/clientes/${entityManager.id}/grupos-exposicao`)}
                  className={classes.title}
                >
                  {`GRUPOS DE EXPOSIÇÃO SIMILAR >>`}
                </div>            
              </Grid>
            </>}
          </Grid>
        }}
      </CrudForm>
    </>
  )
}

export default ClienteForm

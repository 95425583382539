import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#00b4d8',
    },
    secondary: {
      main: '#023e8a', //Fundo Menu
    },
    grey: {
      200: '#E7F0F1', //Fundo
      500: '#E7F0F1', //Texto menu
      800: '#023e8a', //Texto Nome usuário
    },
    common: {
      grey: '#023e8a', //Título da Página
    },
    text: {
      primary: '#000000',
      secondary: '#303030',
    },
  },
})

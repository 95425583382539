import { restApi } from '../../services/api'
import {createBasicRepository as createRepository} from '../../Components/Repository'
import { paginate, query } from '../../lib/feathers'

const fatoresRiscoRepository = createRepository({
  path: 'fatores-risco',
  restApi,
  queryTransform: {
    descricao: query.ilike('descricao'),
    ...paginate
  },
})

export default fatoresRiscoRepository

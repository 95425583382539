import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import cargosRepository from '../repositories/cargosRepository'
import { Button, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Delete } from '@material-ui/icons';
import { Snackbar } from '../../Components/Common';

const useStyles = makeStyles(theme => ({
  clickable: {
    cursor: 'pointer'
  },
  header: {
    fontWeight: 'bold',
  },
}));

const CargosList = props => {
  
  const [cargos, setCargos] = useState([]);
  const [lastRemoved, setLastRemoved] = useState(0);
  const history = useHistory();
 
  const params = useParams();

  const classes = useStyles();

  useEffect(() => {
    ;(async () => {
      const response = await cargosRepository.list({
        paginate: { [`$limit`]: 999 },
        query: { 
          grupoExposicaoId: params.childId,
          '$sort[createdAt]': 'asc'
        }
      });

      if (response.ok) {
        setCargos(response.data)
      }
    })()

  }, [params.childId, lastRemoved]);

  const listOptions = {
    fields: {
      nome: {
        label: 'Nome',
      },
      setor: {
        label: 'Setor',
      },
      gerencia: {
        label: 'Gerência',
      },
    }
  }

  const handleRemove = (id) => {
    cargosRepository.remove({ id, parentId: params.childId });
    setLastRemoved(id)
  }

  const undoRemove = (id) => {
    cargosRepository.update({
      id,
      deletedAt: null,
    });
    setLastRemoved(0);
  };

  return <>
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            {Object.keys(listOptions.fields).map(field => <TableCell key={field} className={classes.header}>
              {listOptions.fields[field].label}
            </TableCell>)}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {cargos.map(cargo => <TableRow hover key={cargo.id}>
            {Object.keys(listOptions.fields).map(field => <TableCell 
              key={`${cargo.id}-${field}`} 
              className={classes.clickable}
              onClick={() => history.push(`/clientes/${params.id}/grupos-exposicao/${params.childId}/cargos/${cargo.id}`)}
            >
              {cargo[field]}
            </TableCell>)}
            <TableCell align='right'>
              <IconButton
                size="small"
                aria-label="Deletar"
                onClick={() => handleRemove(cargo.id)}
              >
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
    
    <Snackbar
      message={lastRemoved && "Cargo removido com sucesso."}
      action={[
        <Button key="undo" color="secondary" size="small" onClick={() => undoRemove(lastRemoved)}>
          DESFAZER
        </Button>,
      ]}
      onClose={() => setLastRemoved(0)}
    />
  </>
}

export default CargosList

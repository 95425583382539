import { restApi } from '../../services/api'
import {createFormDataRepository as createRepository} from '../../Components/Repository'
import { query, paginate } from '../../lib/feathers'

const engenheirosRepository = createRepository({
  path: 'engenheiros',
  restApi,
  queryTransform: {
    nome: query.ilike('nome'),
    ...paginate
  },
})

export default engenheirosRepository

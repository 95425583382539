import React from 'react'
import { useListRepository } from '../../Components/Hooks'
import { List } from '../../Components/Common'
import { useParams } from 'react-router-dom'
import riscosRepository from '../repositories/riscosRepository'
import CopyRiscoDialog from './CopyRiscoDialog'
import { useState } from 'react'
import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
}))

const RiscosList = props => {
 
  const params = useParams();
  const [flagDialogOpened, setFlagDialogOpened] = useState(false);
  const [riscoId, setRiscoId] = useState();

  const onClickCopiar = (id) => {
    setRiscoId(id);
    setFlagDialogOpened(true);
  }
  
  const ButtonCopiar = ({ id }) => {
    const classes = useStyles();
  
    return <div className={classes.buttonContainer}>
      <Button variant="outlined" color="secondary" onClick={async () => onClickCopiar(id) }>
        {"COPIAR"}
      </Button>
    </div>
  }

  const listHook = useListRepository({
    repository: riscosRepository,
    defaultSort: 'ordem',
    path: `clientes/${params.id}/grupos-exposicao/${params.childId}/riscos`,
    query: [
      ['grupoExposicaoId', params.childId]
    ]
  });

  const listOptions = {
    fields: {
      fatorRisco: {
        label: 'Fator',
        format: (fator) => fator?.descricao
      },
      fonteGeradora: {
        label: 'Fonte Geradora',
        format: (fonte) => fonte?.descricao
      },
      id: {
        label: '',
        format: (id) => <ButtonCopiar id={id} />
      }
    }
  }

  return (
    <>
        <List
          {...props}
          {...listHook}
          removeItem={listHook.removeItem}
          onClickEdit={listHook.onClickEdit}
          onClickRow={null}
          onClickView={null}
          onClickNew={null}
          hideDownloadIcon
          listOptions={listOptions}
          emptyText='Ainda não há riscos associados a este GES'
        />
        <CopyRiscoDialog
          riscoId={riscoId}
          open={!!flagDialogOpened}
          handleClose={() => { 
            setFlagDialogOpened(false);
          }}
      />
    </>
  )
}

export default RiscosList

import React from 'react'
import { List } from '../../Components/Common'
import { useListRepository } from '../../Components/Hooks'
import empresasRepository from '../repositories/empresasRepository'

const EmpresasList = ({ ...props }) => {
  const listOptions = {
    defaultOrder: 'nome',
    fields: {
      nome: {
        label: 'Nome'
      }
    }
  }

  const listHook = useListRepository({
    repository: empresasRepository,
    defaultSort: 'nome',
    path: 'empresas'
  })
  
  return (
    <List
      {...props}
      {...listHook}
      removeItem={listHook.removeItem}
      onClickEdit={listHook.onClickEdit}
      onClickNew={listHook.onClickNew}
      onClickView={null}
      hideDownloadIcon
      listOptions={listOptions}
    />
  )
}

export default EmpresasList

import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Field } from 'react-final-form'
import { TextField } from '../../Components/Fields'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import dadosLaudosGesRepository from '../repositories/dadosLaudosGesRepository'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  titleContainer: {
    marginTop: theme.spacing(2)
  }
}));

const DadosLaudosForm = props => {
  const history = useHistory();

  const onGoBack = () => {
    let currentPathname = history.location.pathname
    history.push(currentPathname.substring(0, currentPathname.indexOf("dados-laudos") - 1));
  }

  const entityManager = useEntityManager({
    repository: dadosLaudosGesRepository,
    handleError: data => data.message,
    goBackAfterSave: true,
    relationKey: 'grupoExposicaoId',
    onGoBack
  });

  const classes = useStyles();

  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        withPaper
      >
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.titleContainer}>
            <span className={classes.label}>{"Relatório LTCAT"}</span>
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="conclusaoAdicionalLtcat"
              component={TextField}
              label="Conclusão adicional (após conclusão de cada risco)"
              multiline
            />
          </Grid>

          <Grid item xs={12} className={classes.titleContainer}>
            <span className={classes.label}>{"Relatório Insalubridade"}</span>
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="conclusaoAdicionalInsalubridade"
              component={TextField}
              label="Conclusão adicional (após conclusão de cada risco)"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="ruidoContinuoIntermitente"
              component={TextField}
              label="Ruído Contínuo ou Intermitente"
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="ruidoImpacto"
              component={TextField}
              label="Ruído de Impacto"
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="calor"
              component={TextField}
              label="Calor"
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="radiacaoIonizante"
              component={TextField}
              label="Radiação Ionizante"
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="trabalhoCondicoesHipebaricas"
              component={TextField}
              label="Trabalho sob Condições Hipebáricas"
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="radiacaoNaoIonizante"
              component={TextField}
              label="Radiação Não Ionizante"
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="vibracao"
              component={TextField}
              label="Vibração"
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="frio"
              component={TextField}
              label="Frio"
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="umidade"
              component={TextField}
              label="Umidade"
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="agentesQuimicos"
              component={TextField}
              label="Agentes Químicos"
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="agentesBiologicos"
              component={TextField}
              label="Agentes Biológicos"
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>

          <Grid item xs={12} className={classes.titleContainer}>
            <span className={classes.label}>{"Relatório Periculosidade"}</span>
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="conclusaoPericulosidade"
              component={TextField}
              label="Conclusão do relatório"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="atividadesExplosivos"
              component={TextField}
              label="Atividades e Operações Perigosas com Explosivos"
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="atividadesInflamaveis"
              component={TextField}
              label="Atividades e Operações Perigosas com Inflamáveis"
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="atividadesRadiacoes"
              component={TextField}
              label="Atividades e Operações Perigosas com Radiações Ionizantes ou Substâncias Radioativas"
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="atividadesViolencia"
              component={TextField}
              label="Atividades e Operações Perigosas com Exposição a Violência Física"
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="atividadesEnergia"
              component={TextField}
              label="Atividades e Operações Perigosas com Energia Elétrica "
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="atividadesMotocicleta"
              component={TextField}
              label="Atividades Perigosas em Motocicleta"
              placeholder="Deixar vazio se não há exposição"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="medidasControleColetivas"
              component={TextField}
              label="Medidas de Controle Coletivas"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="medidasControleAdministrativas"
              component={TextField}
              label="Medidas de Controle Administrativas"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="medidasControleIndividuais"
              component={TextField}
              label="Medidas de Controle Individuais"
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="novasMedidasControle"
              component={TextField}
              label="Necessárias novas medidas"
              multiline
            />
          </Grid>
        </Grid>
      </CrudForm>
    </>
  )
}

export default DadosLaudosForm

import React, { useContext } from 'react'
import {
  Switch,
  Redirect,
} from 'react-router-dom'
import * as R from 'ramda'
import { useHistory } from 'react-router';
import { 
  AccountBox, 
  Business, 
  HomeWork, 
  Apps,
  Settings, 
  EmojiPeople,
  Category,
  BlurOn,
  SignalCellularAlt,
} from '@material-ui/icons'
import logoWhite from '../img/logo_white.png' 
import { AppWrap, RouteByMenu} from "./../Components/AppWrap"
import { Route } from 'react-router-dom'
import Home from '../modules/home/Home';
import ClientesList from '../modules/clientes/ClientesList'
import ClienteForm from '../modules/clientes/ClienteForm'
import UsersList from '../modules/users/UsersList'
import UserForm from '../modules/users/UserForm'
import { AuthContext } from '../Components/Login'
import EmpresasList from '../modules/empresas/EmpresasList'
import EmpresaForm from '../modules/empresas/EmpresaForm'
import EngenheirosList from '../modules/engenheiros/EngenheirosList';
import EngenheiroForm from '../modules/engenheiros/EngenheiroForm';
import ConfiguracoesForm from '../modules/configuracoes/ConfiguracoesForm';
import { Paper } from '@material-ui/core';
import TrabalhadoresForm from '../modules/trabalhadores/TrabalhadoresForm';
import TrabalhadoresList from '../modules/trabalhadores/TrabalhadoresList';
import GruposExposicaoList from '../modules/gruposExposicao/GruposExposicaoList';
import GruposExposicaoForm from '../modules/gruposExposicao/GruposExposicaoForm';
import LocaisTrabalhoForm from '../modules/locaisTrabalho/LocaisTrabalhoForm';
import LocaisTrabalhoList from '../modules/locaisTrabalho/LocaisTrabalhoList';
import CargosForm from '../modules/cargos/CargosForm';
import FatoresRiscoList from '../modules/fatoresRisco/FatoresRiscoList';
import FatoresRiscoForm from '../modules/fatoresRisco/FatoresRiscoForm';
import MeiosPropagacaoList from '../modules/meiosPropagacao/MeiosPropagacaoList';
import MeiosPropagacaoForm from '../modules/meiosPropagacao/MeiosPropagacaoForm';
import RiscosForm from '../modules/riscos/RiscosForm';
import PlanoAcaoRiscoForm from '../modules/planosAcaoRisco/PlanoAcaoRiscoForm';
import DadosLtcatForm from '../modules/dadosLtcat/DadosLtcatForm';
import DadosLaudosForm from '../modules/dadosLaudos/DadosLaudosForm';
import ClassesRiscoList from '../modules/classesRisco/ClassesRiscoList';
import ClassesRiscoForm from '../modules/classesRisco/ClassesRiscoForm';
import DetalhesAvaliacaoForm from '../modules/detalhesAvaliacao/DetalhesAvaliacaoForm';

export const AppLoggedRouter = () => {
  const authContext = useContext(AuthContext);
  const user = authContext.user;
  const history = useHistory();

  const Logo = () => ( 
    <div style={{color: 'white', display: 'flex', alignItems: 'center', cursor: 'pointer', justifyContent: 'center'}} onClick={() => history.push('/')}> 
      <img
        alt='logo'
        src={logoWhite}
        style={{ height: 40 }} />
    </div> 
  )
  let menuItems = [
    {
      name: 'clientes',
      label: 'Clientes',
      icon: Business,
      pathname: '/clientes',
      list: ClientesList,
      form: ClienteForm
    },
    {
      label: 'Configurações',
      group: true,
      icon: Settings,
      items: [
        {
          name: 'engenheiros',
          label: 'Engenheiros',
          icon: EmojiPeople,
          pathname: '/engenheiros',
          list: EngenheirosList,
          form: EngenheiroForm
        },
        {
          name: 'configuracoes',
          label: 'Empresa',
          icon: HomeWork,
          pathname: '/configuracoes',
          list: ConfiguracoesForm,
          form: Paper
        },
        {
          name: 'fatores-risco',
          label: 'Fatores de Risco',
          icon: Category,
          pathname: '/fatores-risco',
          list: FatoresRiscoList,
          form: FatoresRiscoForm
        },
        {
          name: 'meios-propagacao',
          label: 'Exposição, Meio de Propagação e Trajetória',
          icon: BlurOn,
          pathname: '/meios-propagacao',
          list: MeiosPropagacaoList,
          form: MeiosPropagacaoForm
        },
        {
          name: 'classes-risco',
          label: 'Classes de Risco',
          icon: SignalCellularAlt,
          pathname: '/classes-risco',
          list: ClassesRiscoList,
          form: ClassesRiscoForm
        },
      ]
    }
  ];

  if (user.role === 'ADMIN') {
    menuItems.push({
      label: 'Administrador',
      group: true,
      icon: Apps,
      items: [
        {
          name: 'empresas',
          label: 'Empresas',
          icon: HomeWork,
          pathname: '/empresas',
          list: EmpresasList,
          form: EmpresaForm
        },
        {
          name: 'users',
          label: 'Usuários',
          icon: AccountBox,
          pathname: '/users',
          list: UsersList,
          form: UserForm
        },
      ]
    })
  }

  const defaultRedirect = R.pathOr('/', [0, 'pathname'], menuItems)

  return (
    <AppWrap
      logo={<Logo />}
      userAvatarProps={{
        action: 'Sair'
      }}
      menuItems={menuItems}>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/clientes/:id/trabalhadores" exact component={TrabalhadoresList} />
        <Route path="/clientes/:id/trabalhadores/:childId" exact component={TrabalhadoresForm} />
        <Route path="/clientes/:id/grupos-exposicao" exact component={GruposExposicaoList} />
        <Route path="/clientes/:id/grupos-exposicao/:childId" exact component={GruposExposicaoForm} />
        <Route path="/clientes/:id/grupos-exposicao/:childId/dados-laudos" exact component={GruposExposicaoForm} />
        <Route path="/clientes/:clienteId/grupos-exposicao/:id/dados-laudos/:childId" exact component={DadosLaudosForm} />
        <Route path="/clientes/:id/locais-trabalho" exact component={LocaisTrabalhoList} />
        <Route path="/clientes/:id/locais-trabalho/:childId" exact component={LocaisTrabalhoForm} />
        <Route path="/clientes/:id/grupos-exposicao/:childId/cargos" exact component={GruposExposicaoForm} />
        <Route path="/clientes/:id/grupos-exposicao/:childId/riscos" exact component={GruposExposicaoForm} />
        <Route path="/clientes/:clienteId/grupos-exposicao/:id/cargos/:childId" exact component={CargosForm} />
        <Route path="/clientes/:clienteId/grupos-exposicao/:id/riscos/:childId" exact component={RiscosForm} />
        <Route path="/clientes/:clienteId/grupos-exposicao/:id/riscos/:childId/planos-acao" exact component={RiscosForm} />
        <Route path="/clientes/:clienteId/grupos-exposicao/:id/riscos/:childId/dados-ltcat" exact component={RiscosForm} />
        <Route path="/clientes/:clienteId/grupos-exposicao/:id/riscos/:childId/detalhes-avaliacao" exact component={RiscosForm} />
        <Route path="/clientes/:clienteId/grupos-exposicao/:grupoExposicaoId/riscos/:id/planos-acao/:childId" exact component={PlanoAcaoRiscoForm} />
        <Route path="/clientes/:clienteId/grupos-exposicao/:grupoExposicaoId/riscos/:id/dados-ltcat/:childId" exact component={DadosLtcatForm} />
        <Route path="/clientes/:clienteId/grupos-exposicao/:grupoExposicaoId/riscos/:id/detalhes-avaliacao/:childId" exact component={DetalhesAvaliacaoForm} />
        <RouteByMenu menuItems={menuItems} />
        <Redirect to={defaultRedirect} />
      </Switch>
    </AppWrap>
  )
}


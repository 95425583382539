import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import green from '@material-ui/core/colors/green'
import red from '@material-ui/core/colors/red'
import { PureAutocomplete, Snackbar } from '../../Components/Common';
import { useEffect } from 'react';
import clientesRepository from '../repositories/clientesRepository'
import copyRiscoRepository from '../repositories/copyRiscoRepository'
import gruposExposicaoRepository from '../repositories/gruposExposicaoRepository'
import riscosRepository from '../repositories/riscosRepository';

const StatusDialog = {
    default: 'default',
    running: 'running',
    success: 'success',
    error: 'error'
}

const useStyles = makeStyles(theme => ({
    successButton: {
        color: green[500],
      },
    errorButton: {
        color: red[500],
    },
    line: {
        width: 400,
    },
    actions: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    closeButton: {
        marginRight: theme.spacing(2)
    }
}));

export default function CopyRiscoDialog(props) {
    const { open, handleClose, riscoId } = props;

    const [statusDialog, setStatusDialog] = useState(StatusDialog.default);
    const [errorMessage, setErrorMessage] = useState();
    
    const [risco, setRisco] = useState();
    const [clientes, setClientes] = useState([]);
    const [gruposExposicao, setGruposExposicao] = useState([]);

    const [clienteId, setClienteId] = useState();
    const [grupoExposicaoId, setGrupoExposicaoId] = useState();

    useEffect(() => {
        ;(async () => {
          const response = await clientesRepository.list({
            paginate: { [`$limit`]: 999 },
          });
    
          if (response.ok) {
            setClientes(response.data.map(item => ({
              label: item.nome.length > 40 ? item.nome.substring(0, 40) + '..' : item.nome,
              value: item.id,
            })))
          }
        })()
    }, []);

    useEffect(() => {
        ;(async () => {
            if (!clienteId) {
                return;
            }

            const response = await gruposExposicaoRepository.list({
                query: {
                    clienteId,
                },
                paginate: { [`$limit`]: 999 },
            });
        
            if (response.ok) {
                setGruposExposicao(response.data.map(item => ({
                    label: `${item.codigo} - ${item.descricao}`,
                    value: item.id,
                })))
            }
        })()
    }, [clienteId]);

    useEffect(() => {
        ;(async () => {
            if (!riscoId) {
                return;
            }
            const response = await riscosRepository.getOne({
                id: riscoId,
            });
        
            if (response.ok) {
                setRisco(response.data);
            }
        })()
    }, [riscoId]);

    const confirmButtonClass = () => {
        if (statusDialog === StatusDialog.success) {
            return classes.successButton;
        }

        if (statusDialog === StatusDialog.error){
            return classes.errorButton;
        }
    }

    const confirmButtonText = () => {
        if (statusDialog === StatusDialog.running){
            return "Copiando..."
        }
        
        if (statusDialog === StatusDialog.success) {
            return "Successo"
        }
        if (statusDialog === StatusDialog.error) {
            return "Ocorreu um erro"
        }
        
        return "Copiar";
    }

    const onSubmit = async () => {
        if (!grupoExposicaoId) {
            setStatusDialog(StatusDialog.error);
            setErrorMessage('É necessário selecionar o GES');
            setTimeout(() => {
                setStatusDialog(StatusDialog.default);
            }, 2000);
        }
        setStatusDialog(StatusDialog.running);
        const response = await copyRiscoRepository.create({ id: riscoId, grupoExposicaoId });
        if (!response.ok) {
            setStatusDialog(StatusDialog.error);
            setErrorMessage(response.data.message);
            setTimeout(() => {
                setStatusDialog(StatusDialog.default);
            }, 2000);
        } else {
            setStatusDialog(StatusDialog.success);
            setTimeout(() => {
                handleClose();
            }, 2000);
        }
    }

    const classes = useStyles();

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Copiar Risco'}</DialogTitle>

                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} className={classes.line}>
                            <PureAutocomplete
                                fullWidth
                                value={clientes?.find(c => String(c.value) === String(clienteId))}
                                onChange={(item) => setClienteId(item?.value)}
                                label="Cliente"
                                options={clientes}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.line}>
                            <PureAutocomplete
                                fullWidth
                                value={gruposExposicao?.find(c => String(c.value) === String(grupoExposicaoId))}
                                onChange={(item) => setGrupoExposicaoId(item.value)}
                                label="GES"
                                options={gruposExposicao}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <br/> 
                            Fator Risco: {risco?.fatorRisco?.descricao}
                            <br/> 
                            Fonte Geradora: {risco?.fonteGeradora?.descricao}
                            <br/> 
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color={"default"} className={classes.closeButton}>
                        {'Fechar'}
                    </Button>
                    <Button 
                        onClick={onSubmit}
                        className={confirmButtonClass()}
                        color="primary"
                        variant='contained'
                    >
                        {confirmButtonText()}
                    </Button>
                    
                </DialogActions>

                <Snackbar
                    onClose={() => setErrorMessage(null)}
                    message={errorMessage}/>

            </Dialog>

        </div>
    )
}

import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { Field } from 'react-final-form'
import { TextField } from '../../Components/Fields'
import locaisTrabalhoRepository from '../repositories/locaisTrabalhoRepository'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'
import FotosLocalTrabalho from './FotosLocalTrabalho'
import { useState } from 'react'

const useStyles = makeStyles(theme => ({
  mustSaveContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  mustSaveText: {
    fontSize: 14,
    color: theme.palette.secondary.main
  },
  mustSaveLink: {
    marginLeft: 4,
    fontSize: 14,
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
}))

const LocaisTrabalhoForm = (props) => {
  
  const [message, setMessage] = useState();

  const entityManager = useEntityManager({
    repository: locaisTrabalhoRepository,
    relationKey: 'clienteId',
    handleError: data => data.message,
  });
  
  const schema = yup.object().shape({
    descricao: yup.string().required('Obrigatório')
  })
  
  const validate = yupValidation(schema);

  const classes = useStyles();

  return <CrudForm
    {...props}
    {...entityManager}
    withPaper
    validate={validate}
    message={message}
    clearMessage={() => setMessage(undefined)}
  >
    {({form}) => <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="descricao"
          label="Descricao"
          component={TextField}
        />
      </Grid>
      {entityManager.isNew && <Grid item xs={12} className={classes.mustSaveContainer}>
        <span className={classes.mustSaveText}>{"Para adicionar fotos, "}</span>
        <span className={classes.mustSaveLink} onClick={() => form.submit()}>{"salve as alterações"}</span>
      </Grid>}
      
      {!entityManager.isNew && <Grid item xs={12}>
        <FotosLocalTrabalho localTrabalhoId={entityManager.childId} setMessage={setMessage} />
      </Grid>}
    </Grid>}
  </CrudForm>
}

export default LocaisTrabalhoForm;
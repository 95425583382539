import React from 'react'
import { Filter, List } from '../../Components/Common'
import { useFilter, useListRepository } from '../../Components/Hooks'
import { TextField } from '../../Components/Fields'
import { Field } from 'react-final-form'
import { Grid, Button, makeStyles } from '@material-ui/core'
import CNPJField from '../../Components/Fields/CNPJField'
import clientesRepository from '../repositories/clientesRepository'

const useStyles = makeStyles(theme => ({
  buttonRow: {
    marginTop: theme.spacing(2),
  },
}));

const ClientesFilter = () => {
  const filter = useFilter({})
  return (
    <Filter {...filter} labels={{ find: 'Buscar', clear: 'Limpar' }}>
      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <Field
            name="cnpj"
            label="CNPJ"
            fullWidth
            component={CNPJField}
          />
        </Grid>
        <Grid item sm={8} xs={12}>
          <Field
            fullWidth
            name="nome"
            label="Nome"
            component={TextField}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

const formatCNPJ = (v) => {
  v=v.replace(/\D/g,"")
  v=v.replace(/^(\d{2})(\d)/,"$1.$2")
  v=v.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
  v=v.replace(/\.(\d{3})(\d)/,".$1/$2")
  v=v.replace(/(\d{4})(\d)/,"$1-$2")
  return v
}

const ClientesList = ({ ...props }) => {

  const classes = useStyles();

  const listOptions = {
    defaultOrder: 'nome',
    fields: {
      cnpj: {
        label: 'CNPJ',
        format: cnpj => formatCNPJ(cnpj)
      },
      nome: {
        label: 'Nome'
      },
    }
  }

  const listHook = useListRepository({
    repository: clientesRepository,
    defaultSort: 'nome',
    path: 'clientes'
  })
  
  return (
    <>
      <ClientesFilter />
      <List
        {...props}
        {...listHook}
        removeItem={listHook.removeItem}
        onClickEdit={listHook.onClickEdit}
        onClickRow={listHook.onClickEdit}
        onClickView={null}
        onClickNew={null}
        hideDownloadIcon
        listOptions={listOptions}
      />
      <Grid container spacing={2} direction="row-reverse" className={classes.buttonRow}>
        <Grid item xs={12} md={3}>
          <Button
            fullWidth
            onClick={listHook.onClickNew}
            variant='contained'
            color='primary'>
            Adicionar
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default ClientesList

import React from 'react'
import { List } from '../../Components/Common'
import { useListRepository } from '../../Components/Hooks'
import classesRiscoRepository from '../repositories/classesRiscoRepository'

const ClassesRiscoList = ({ ...props }) => {
  const listOptions = {
    defaultOrder: 'descricao',
    fields: {
      descricao: {
        label: 'Classe Risco'
      }
    }
  }

  const listHook = useListRepository({
    repository: classesRiscoRepository,
    defaultSort: 'descricao',
    path: 'classes-risco'
  })
  
  return (
    <>
      <List
        {...props}
        {...listHook}
        removeItem={listHook.removeItem}
        onClickEdit={listHook.onClickEdit}
        onClickNew={listHook.onClickNew}
        onClickView={null}
        hideDownloadIcon
        listOptions={listOptions}
      />
    </>
  )
}

export default ClassesRiscoList

import React, { useEffect, useState } from 'react'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { Field } from 'react-final-form'
import { Autocomplete, TextField } from '../../Components/Fields'
import gruposExposicaoRepository from '../repositories/gruposExposicaoRepository'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'
import CargosList from '../cargos/CargosList'
import { useHistory, useParams } from 'react-router-dom'
import RiscosList from '../riscos/RiscosList'
import DadosLaudosGes from '../dadosLaudos/DadosLaudosGes'
import locaisTrabalhoRepository from '../repositories/locaisTrabalhoRepository'
import { restApi } from '../../services/api'
import { saveAs } from 'file-saver'
import DescriptionIcon from '@material-ui/icons/Description'

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 15,
    fontWeight: 'bold',
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    marginTop: theme.spacing(1)
  },
  mustSaveContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  mustSaveText: {
    fontSize: 14,
    color: theme.palette.secondary.main
  },
  mustSaveLink: {
    marginLeft: 4,
    fontSize: 14,
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  buttonAddContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  downloadButton: {
    width: 200,
    display: 'flex',
    justifyContent: 'space-around',
  }
}))

const GruposExposicaoForm = (props) => {

  const params = useParams();

  const [cargosIn, setCargosIn] = useState(true);
  const [riscosIn, setRiscosIn] = useState(true);
  const [locaisTrabalho, setLocaisTrabalho] = useState();
  const [downloadingLTCAT, setDownloadingLTCAT] = useState(false);
  const [downloadingInsalubridade, setDownloadingInsalubridade] = useState(false);
  const [downloadingPericulosidade, setDownloadingPericulosidade] = useState(false);
  
  const downloadLTCAT = async () => {
    setDownloadingLTCAT(true);
    const response = await restApi.get(`ltcat-report/${entityManager.childId}`, {}, { responseType: `blob` })
    try {
      if (response.ok && response.data) {
        saveAs(response.data, `LTCAT ${entityManager.item?.descricao || entityManager.id}.pdf`)
      } else {
        entityManager.setError("Erro ao gerar relatório");
      }
    } catch (e) {
      entityManager.setError("Erro ao gerar relatório");
    } finally {
      setDownloadingLTCAT(false);
    }
  }

  const downloadInsalubridade = async () => {
    setDownloadingInsalubridade(true);
    const response = await restApi.get(`insalubridade-report/${entityManager.childId}`, {}, { responseType: `blob` })
    try {
      if (response.ok && response.data) {
        saveAs(response.data, `Insalubridade ${entityManager.item?.descricao || entityManager.id}.pdf`)
      } else {
        entityManager.setError("Erro ao gerar relatório");
      }
    } catch (e) {
      entityManager.setError("Erro ao gerar relatório");
    } finally {
      setDownloadingInsalubridade(false);
    }
  }

  const downloadPericulosidade = async () => {
    setDownloadingPericulosidade(true);
    const response = await restApi.get(`periculosidade-report/${entityManager.childId}`, {}, { responseType: `blob` })
    try {
      if (response.ok && response.data) {
        saveAs(response.data, `Periculosidade ${entityManager.item?.descricao || entityManager.id}.pdf`)
      } else {
        entityManager.setError("Erro ao gerar relatório");
      }
    } catch (e) {
      entityManager.setError("Erro ao gerar relatório");
    } finally {
      setDownloadingPericulosidade(false);
    }
  }

  useEffect(() => {
    ;(async () => {
      const response = await locaisTrabalhoRepository.list({
        paginate: { [`$limit`]: 999 },
        query: { clienteId: params.id }
      });

      if (response.ok) {
        setLocaisTrabalho(response.data.map(item => ({
          label: item.descricao,
          value: item.id
        })))
      }
    })()

  }, [params.id]);

  const entityManager = useEntityManager({
    repository: gruposExposicaoRepository,
    relationKey: 'clienteId',
  });
  
  const schema = yup.object().shape({
    codigo: yup.string().required('Obrigatório'),
    descricao: yup.string().required('Obrigatório')
  })
  
  const validate = yupValidation(schema);

  const classes = useStyles();
  const history = useHistory();

  return <CrudForm
    {...props}
    {...entityManager}
    withPaper
    validate={validate}
    customActions={[{
      label: 'Relatorio LTCAT',
      onClick: downloadLTCAT,
      icon: <DescriptionIcon/>,
      loading: downloadingLTCAT,
      disabled: (form) => entityManager.isNew || form.getState().dirty,
      props: {
        color: 'secondary',
        className: classes.downloadButton
      }
    }, {
      label: 'Insalubridade',
      onClick: downloadInsalubridade,
      icon: <DescriptionIcon/>,
      loading: downloadingInsalubridade,
      disabled: (form) => entityManager.isNew || form.getState().dirty,
      props: {
        color: 'secondary',
        className: classes.downloadButton
      }
    }, {
      label: 'Periculosidade',
      onClick: downloadPericulosidade,
      icon: <DescriptionIcon/>,
      loading: downloadingPericulosidade,
      disabled: (form) => entityManager.isNew || form.getState().dirty,
      props: {
        color: 'secondary',
        className: classes.downloadButton
      }
    }
  ]}
  >
    {({ form }) => {
      const isDirty = entityManager.isNew || form.getState().dirty;

      return <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Field
            fullWidth
            name="codigo"
            label="Código"
            component={TextField}
          />
        </Grid> 
        <Grid item xs={12} sm={8}>
          <Field
            fullWidth
            name="descricao"
            label="Descrição"
            component={TextField}
          />
        </Grid>
        
        <Grid item xs={12}>
          <Field
            fullWidth
            name="localTrabalhoId"
            label="Local de Trabalho"
            component={Autocomplete}
            options={locaisTrabalho}
          />
        </Grid>

        <Grid item xs={12}>
          <Field
            fullWidth
            name="observacao"
            label="Observações"
            component={TextField}
            multiline
          />
        </Grid>

        <Grid item xs={12}>
          <div onClick={() => setCargosIn(!cargosIn)}
            className={classes.title}
          >
            {`CARGOS ${cargosIn ? '<<' : '>>'}`}
          </div>
        </Grid>

        {cargosIn && <>
          <Grid item xs={12}>
            {isDirty && <div className={classes.mustSaveContainer}>
              <span className={classes.mustSaveText}>{"Para adicionar e editar cargos, "}</span>
              <span className={classes.mustSaveLink} onClick={() => form.submit()}>{"salve as alterações"}</span>
            </div>}
            
            {!isDirty && <CargosList {...props} />}
              
          </Grid>
          
          {!isDirty && <Grid item xs={12} className={classes.buttonAddContainer}>
            <Button onClick={() => history.push(`/clientes/${params.id}/grupos-exposicao/${params.childId}/cargos/new`)} variant='outlined' color='secondary'>
              {"Adicionar Cargo"}
            </Button>
          </Grid>}
        </>}

        <Grid item xs={12}>
          <div onClick={() => setRiscosIn(!riscosIn)}
            className={classes.title}
          >
            {`RISCOS ${riscosIn ? '<<' : '>>'}`}
          </div>
        </Grid>

        {riscosIn && <>
          <Grid item xs={12}>
            {isDirty && <div className={classes.mustSaveContainer}>
              <span className={classes.mustSaveText}>{"Para adicionar e editar riscos, "}</span>
              <span className={classes.mustSaveLink} onClick={() => form.submit()}>{"salve as alterações"}</span>
            </div>}
            
            {!isDirty && <RiscosList {...props} />}
              
          </Grid>
          
          {!isDirty && <Grid item xs={12} className={classes.buttonAddContainer}>
            <Button onClick={() => history.push(`/clientes/${params.id}/grupos-exposicao/${params.childId}/riscos/new`)} variant='outlined' color='secondary'>
              {"Adicionar Risco"}
            </Button>
          </Grid>}
        </>}

        {!isDirty && <DadosLaudosGes grupoExposicaoId={entityManager.childId}/>}

      </Grid>}
    }
  </CrudForm>
}

export default GruposExposicaoForm;
import config from '../config'
import { create } from 'apisauce'

export const restApi = create({
  baseURL: config.baseURL
})

const getApi = () => {

  return {
    setToken: token => restApi.setHeader('Authorization', `Bearer ${token}`),
    ping: () => restApi.get('/ping?$limit=1'),
    trabalhadoresImportacao: (formData) => restApi.post('/trabalhadores/importacao', formData, {
      headers: {
        'Content-Type': `multipart/form-data`,
      },
    }),
    login: (credentials) => restApi.post('/authentication', {
      strategy: "local",
      ...credentials
    }),
    resetPassword: data => restApi.post('/account', data),
  }
}



export default getApi()

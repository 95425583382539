import React from 'react'
import { Filter, List } from '../../Components/Common'
import { useFilter, useListRepository } from '../../Components/Hooks'
import fatoresRiscoRepository from '../repositories/fatoresRiscoRepository'
import { Select, TextField } from '../../Components/Fields'
import { Grid } from '@material-ui/core'
import { Field } from 'react-final-form'

const FatoresRiscoFilter = () => {
  const filter = useFilter({})
  return (
    <Filter {...filter} labels={{ find: 'Buscar', clear: 'Limpar' }}>
      <Grid container spacing={3}>
        <Grid item sm={4} xs={12}>
          <Field
            fullWidth
            name="tipoRisco"
            component={Select}
            label="Tipo"
            options={[
              {
                value: 'FISICO',
                label: 'Físico'
              },
              {
                value: 'QUIMICO',
                label: 'Químico'
              },
              {
                value: 'BIOLOGICO',
                label: 'Biológico'
              },
              {
                value: 'MECANICO',
                label: 'Mecânico'
              },
              {
                value: 'ERGONOMICO',
                label: 'Ergonômico'
              }
            ]}
          />
        </Grid>
        <Grid item sm={8} xs={12}>
          <Field
            fullWidth
            name="descricao"
            label="Descrição"
            component={TextField}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

const FatoresRiscoList = ({ ...props }) => {
  const listOptions = {
    defaultOrder: 'descricao',
    fields: {
      tipoRisco: {
        label: 'Tipo'
      },
      descricao: {
        label: 'Descrição'
      }
    }
  }

  const listHook = useListRepository({
    repository: fatoresRiscoRepository,
    defaultSort: 'descricao',
    path: 'fatores-risco'
  })
  
  return (
    <>
      <FatoresRiscoFilter />
      <List
        {...props}
        {...listHook}
        removeItem={listHook.removeItem}
        onClickEdit={listHook.onClickEdit}
        onClickNew={listHook.onClickNew}
        onClickView={null}
        hideDownloadIcon
        listOptions={listOptions}
      />
    </>
  )
}

export default FatoresRiscoList

import React from 'react'
import Grid from '@material-ui/core/Grid'
import { Field } from 'react-final-form'
import { TextField, Select } from '../../Components/Fields'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'
import fatoresRiscoRepository from '../repositories/fatoresRiscoRepository'

const schema = yup.object().shape({
  descricao: yup.string().required('Obrigatório'),
  tipoRisco: yup.string().required('Obrigatório'),
})

const validate = yupValidation(schema)

const FatoresRiscoForm = props => {

  const entityManager = useEntityManager({
    repository: fatoresRiscoRepository,
    handleError: data => data.message,
    goBackAfterSave: true
  });

  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        validate={validate}
        withPaper
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} >
            <Field
              fullWidth
              name="tipoRisco"
              component={Select}
              label="Tipo"
              options={[
                {
                  value: 'FISICO',
                  label: 'Físico'
                },
                {
                  value: 'QUIMICO',
                  label: 'Químico'
                },
                {
                  value: 'BIOLOGICO',
                  label: 'Biológico'
                },
                {
                  value: 'MECANICO',
                  label: 'Mecânico'
                },
                {
                  value: 'ERGONOMICO',
                  label: 'Ergonômico'
                }
              ]}
            />
          </Grid>
          <Grid item xs={12} md={4} >
            <Field
              fullWidth
              name="ordem"
              component={TextField}
              label="Ordem"
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={4} >
            <Field
              fullWidth
              name="codigoESocial"
              component={TextField}
              label="Código E-Social"
            />
          </Grid>
          <Grid item xs={12} >
            <Field
              fullWidth
              name="descricao"
              component={TextField}
              label="Descrição"
            />
          </Grid>                
          <Grid item xs={12} >
            <Field
              fullWidth
              name="danosSaude"
              component={TextField}
              label="Danos à saúde"
            />
          </Grid>                
          <Grid item xs={12} md={4}>
            <Field
              fullWidth
              name="tecnicaUtilizada"
              component={TextField}
              label="Técnica Utilizada"
            />
          </Grid>                
          <Grid item xs={12} md={4}>
            <Field
              fullWidth
              name="ltLe"
              component={TextField}
              label="LT / LE"
            />
          </Grid>                
          <Grid item xs={12} md={4}>
            <Field
              fullWidth
              name="nivelAcao"
              component={TextField}
              label="Nível de Ação"
            />
          </Grid>                
        </Grid>
      </CrudForm>
    </>
  )
}

export default FatoresRiscoForm

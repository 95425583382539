import { restApi } from '../../services/api'
import { createBasicRepository as createRepository } from '../../Components/Repository'
import { query, paginate } from '../../lib/feathers'

const cargosRepository = createRepository({
  path: 'cargos',
  restApi,
  queryTransform: {
    nome: query.ilike('nome'),
    ...paginate
  },
})

export default cargosRepository

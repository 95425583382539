import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { Field } from 'react-final-form'
import { Select, TextField } from '../../Components/Fields'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'
import classesRiscoRepository from '../repositories/classesRiscoRepository'

const schema = yup.object().shape({
  descricao: yup.string().required('Obrigatório'),
  codigoCor: yup.string().required('Obrigatório'),
})

const validate = yupValidation(schema)

const ClassesRiscoForm = props => {

  const entityManager = useEntityManager({
    repository: classesRiscoRepository,
    handleError: data => data.message,
    goBackAfterSave: true
  });

  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        validate={validate}
        withPaper
      >
        <Grid container spacing={2}>
          <Grid item sm={8} xs={12} >
            <Field
              fullWidth
              name="descricao"
              component={TextField}
              label="Descrição"
            />
          </Grid>                
          <Grid item sm={4} xs={12} >
            <Field
              fullWidth
              name="codigoCor"
              component={Select}
              label="Cor"
              options={[
                {
                  value: '#ffff00',
                  label: <Typography style={{color: '#ffff00'}}>Amarelo</Typography>
                },
                {
                  value: '#00b0f0',
                  label: <Typography style={{color: '#00b0f0'}}>Azul</Typography>
                },
                {
                  value: '#ffc000',
                  label: <Typography style={{color: '#ffc000'}}>Laranja</Typography>
                },
                {
                  value: '#92d050',
                  label: <Typography style={{color: '#92d050'}}>Verde</Typography>
                },
                {
                  value: '#ff0000',
                  label: <Typography style={{color: '#ff0000'}}>Vermelho</Typography>
                },
              ]}
            />
          </Grid>                
        </Grid>
      </CrudForm>
    </>
  )
}

export default ClassesRiscoForm

import { restApi } from '../../services/api'
import { createFormDataRepository as createRepository } from '../../Components/Repository'
import { handleFileField } from './repositoryUtils';

const fotosLocalTrabalhoRepository = createRepository({
  path: 'fotos-local-trabalho',
  restApi,
  listResponsePath: {
    pathData: ['data'],
    pathCount: ['total'],
  },
  dataTransform: (data) => {
    handleFileField(data, 'foto', 'fotoUrl');

    return data;
  }
})

export default fotosLocalTrabalhoRepository

import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import detalhesAvaliacaoRepository from '../repositories/detalhesAvaliacaoRepository'
import { Button, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Delete } from '@material-ui/icons';
import { Snackbar } from '../../Components/Common';

const useStyles = makeStyles(theme => ({
  clickable: {
    cursor: 'pointer'
  },
  header: {
    fontWeight: 'bold',
  },
}));

const DetalhesAvaliacaoList = props => {
  const {detalhesAvaliacao, lastRemoved, setLastRemoved} = props;

  const history = useHistory();
 
  const params = useParams();

  const classes = useStyles();

  const listOptions = {
    fields: {
      trabalhador: {
        label: 'Trabalhador',
        format: (trabalhador) => trabalhador?.nome
      },
      intensidadeConcentracao: {
        label: 'Intens. / Conc.',
      },
    }
  }

  const handleRemove = (id) => {
    detalhesAvaliacaoRepository.remove({ id, parentId: params.childId });
    setLastRemoved(id)
  }

  const undoRemove = (id) => {
    detalhesAvaliacaoRepository.update({
      id,
      deletedAt: null,
    });
    setLastRemoved(0);
  };

  return <>
    <TableContainer component={Paper}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            {Object.keys(listOptions.fields).map(field => <TableCell key={field} className={classes.header}>
              {listOptions.fields[field].label}
            </TableCell>)}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {detalhesAvaliacao.map(detalhe => <TableRow hover key={detalhe.id}>
            {Object.keys(listOptions.fields).map(field => <TableCell 
              key={`${detalhe.id}-${field}`} 
              className={classes.clickable}
              onClick={() => history.push(`/clientes/${params.clienteId}/grupos-exposicao/${params.id}/riscos/${params.childId}/detalhes-avaliacao/${detalhe.id}`)}
            >
              {listOptions.fields[field].format ? listOptions.fields[field].format(detalhe[field]) : detalhe[field]}
            </TableCell>)}
            <TableCell align='right'>
              <IconButton
                size="small"
                aria-label="Deletar"
                onClick={() => handleRemove(detalhe.id)}
              >
                <Delete />
              </IconButton>
            </TableCell>
          </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
    
    <Snackbar
      message={lastRemoved && "Trabalhador removido com sucesso."}
      action={[
        <Button key="undo" color="secondary" size="small" onClick={() => undoRemove(lastRemoved)}>
          DESFAZER
        </Button>,
      ]}
      onClose={() => setLastRemoved(0)}
    />
  </>
}

export default DetalhesAvaliacaoList

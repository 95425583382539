import React from 'react'
import { Grid } from '@material-ui/core'
import { Field } from 'react-final-form'
import { TextField } from '../../Components/Fields'
import cargosRepository from '../repositories/cargosRepository'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'
import { useHistory } from 'react-router-dom'

const CargosForm = (props) => {
  const history = useHistory();

  const onGoBack = () => {
    let currentPathname = history.location.pathname
    history.push(currentPathname.substring(0, currentPathname.indexOf("cargos") - 1));
  }

  const entityManager = useEntityManager({
    repository: cargosRepository,
    relationKey: 'grupoExposicaoId',
    onGoBack
  });
  
  const schema = yup.object().shape({
    nome: yup.string().required('Obrigatório'),
  })
  
  const validate = yupValidation(schema);

  return <CrudForm
    {...props}
    {...entityManager}
    withPaper
    validate={validate}
  >
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="nome"
          label="Nome"
          component={TextField}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field
          fullWidth
          name="setor"
          label="Setor"
          component={TextField}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field
          fullWidth
          name="gerencia"
          label="Gerência"
          component={TextField}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Field
          fullWidth
          name="jornada"
          label="Jornada (min)"
          component={TextField}
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          fullWidth
          name="descricaoAtividade"
          label="Descrição da Atividade"
          component={TextField}
          multiline
        />
      </Grid>
    </Grid>
  </CrudForm>
}

export default CargosForm;
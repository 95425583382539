import { restApi } from '../../services/api'
import { createBasicRepository as createRepository } from '../../Components/Repository'
import { query, paginate } from '../../lib/feathers'

const trabalhadoresRepository = createRepository({
  path: 'trabalhadores',
  restApi,
  queryTransform: {
    nome: query.ilike('nome'),
    ...paginate
  },
})

export default trabalhadoresRepository

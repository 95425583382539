import { restApi } from '../../services/api'
import { createBasicRepository as createRepository } from '../../Components/Repository'
import { query, paginate } from '../../lib/feathers'

const medidasControleRepository = createRepository({
  path: 'medidas-controle',
  restApi,
  queryTransform: {
    descricao: query.ilike('descricao'),
    ...paginate
  },
})

export default medidasControleRepository

import { restApi } from '../../services/api'
import { createBasicRepository as createRepository } from '../../Components/Repository'
import { query, paginate } from '../../lib/feathers'

const locaisTrabalhoRepository = createRepository({
  path: 'locais-trabalho',
  restApi,
  queryTransform: {
    descricao: query.ilike('descricao'),
    ...paginate
  },
})

export default locaisTrabalhoRepository

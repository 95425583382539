import React from 'react'
import { useFilter, useListRepository } from '../../Components/Hooks'
import { Filter, List } from '../../Components/Common'
import { useHistory, useParams } from 'react-router-dom'
import locaisTrabalhoRepository from '../repositories/locaisTrabalhoRepository'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { Field } from 'react-final-form'
import { TextField } from '../../Components/Fields'

const useStyles = makeStyles(theme => ({
  buttonRow: {
    marginTop: theme.spacing(2),
  },
}));

const LocaisTrabalhoFilter = () => {
  const filter = useFilter({})
  return (
    <Filter {...filter} labels={{ find: 'Buscar', clear: 'Limpar' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Field
            fullWidth
            name="ges"
            label="GES"
            component={TextField}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Field
            fullWidth
            name="descricao"
            label="Descrição"
            component={TextField}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

const LocaisTrabalhoList = props => {
 
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();

  const listHook = useListRepository({
    repository: locaisTrabalhoRepository,
    defaultSort: 'createdAt',
    path: `clientes/${params.id}/locais-trabalho`,
    query: [
      ['clienteId', params.id]
    ]
  });

  const listOptions = {
    fields: {
      gruposExposicao: {
        label: 'GES',
        format: (gruposExposicao) => gruposExposicao.map(ges => `${ges.codigo} - ${ges.descricao}`).join(';'),
        noOrder: true
      },
      descricao: {
        label: 'Local de Trabalho',
      },
    }
  }

  return (
    <>
      <LocaisTrabalhoFilter />
      <List
        {...props}
        {...listHook}
        removeItem={listHook.removeItem}
        onClickEdit={listHook.onClickEdit}
        onClickRow={listHook.onClickEdit}
        onClickView={null}
        onClickNew={null}
        hideDownloadIcon
        listOptions={listOptions}
        emptyText='Ainda não há locais de trabalho associados a este cliente'
      />
      <Grid container spacing={2} direction="row-reverse" className={classes.buttonRow}>
        <Grid item xs={12} md={3}>
          <Button
            fullWidth
            onClick={listHook.onClickNew}
            variant='contained'
            color='primary'>
            Adicionar
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            fullWidth
            onClick={() => {
              let currentPathname = history.location.pathname
              history.push(currentPathname.substring(0, currentPathname.indexOf("locais-trabalho") - 1))
            }}
            variant='outlined'
            color='secondary'>
            {"<< Voltar"}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default LocaisTrabalhoList

import React, { useState, useEffect } from 'react'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import { Field } from 'react-final-form'
import { Autocomplete, AutocompleteCreatable, Select, TextField } from '../../Components/Fields'
import riscosRepository from '../repositories/riscosRepository'
import fatoresRiscoRepository from '../repositories/fatoresRiscoRepository'
import { Checkbox, CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'
import { useHistory, useParams } from 'react-router-dom'
import fontesGeradorasRepository from '../repositories/fontesGeradorasRepository'
import meiosPropagacaoRepository from '../repositories/meiosPropagacaoRepository'
import classesRiscoRepository from '../repositories/classesRiscoRepository'
import medidasControleRepository from '../repositories/medidasControleRepository'
import PlanosAcaoRiscoList from '../planosAcaoRisco/PlanosAcaoRiscoList'
import { setValue } from '../../utils/utils'
import DetalhesAvaliacaoList from '../detalhesAvaliacao/DetalhesAvaliacaoList'
import detalhesAvaliacaoRepository from '../repositories/detalhesAvaliacaoRepository'

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
  },
  observacao: {
    marginTop: theme.spacing(1),
    fontSize: 10,
    color: theme.palette.secondary.main,
    fontStyle: 'italic'
  },
  mustSaveContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  mustSaveText: {
    fontSize: 14,
    color: theme.palette.secondary.main
  },
  mustSaveLink: {
    marginLeft: 4,
    fontSize: 14,
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  buttonAddContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    margin: theme.spacing(1)
  },
}))

const RiscosForm = (props) => {

  const params = useParams();
  const classes = useStyles();
  const history = useHistory();

  const [fatoresRisco, setFatoresRisco] = useState();
  const [meiosPropagacao, setMeiosPropagacao] = useState();
  const [classesRisco, setClassesRisco] = useState([]);
  const [detalhesAvaliacao, setDetalhesAvaliacao] = useState([]);
  const [lastRemoved, setLastRemoved] = useState(0);

  useEffect(() => {
    ;(async () => {
      const response = await fatoresRiscoRepository.list({
        paginate: { [`$limit`]: 999 },
      });

      if (response.ok) {
        setFatoresRisco(response.data.map(item => ({
          label: item.descricao,
          value: item.id,
          tecnicaUtilizada: item.tecnicaUtilizada,
          ltLe: item.ltLe,
          nivelAcao: item.nivelAcao,
        })))
      }
    })()

  }, []);

  useEffect(() => {
    ;(async () => {
      const response = await meiosPropagacaoRepository.list({
        paginate: { [`$limit`]: 999 },
      });

      if (response.ok) {
        setMeiosPropagacao(response.data.map(item => ({
          label: item.descricao,
          value: item.id
        })))
      }
    })()

  }, []);

  useEffect(() => {
    ;(async () => {
      const response = await classesRiscoRepository.list({
        paginate: { [`$limit`]: 999 },
      });

      if (response.ok) {
        setClassesRisco(response.data.map(item => ({
          label: item.descricao,
          value: item.id,
          cor: item.codigoCor
        })))
      }
    })()

  }, []);

  useEffect(() => {
    ;(async () => {
      if (params.childId === 'new') {
        return;
      }
      
      const response = await detalhesAvaliacaoRepository.list({
        paginate: { [`$limit`]: 999 },
        query: { 
          riscoId: params.childId,
          '$sort[createdAt]': 'asc'
        }
      });

      if (response.ok) {
        setDetalhesAvaliacao(response.data)
      }
    })()

  }, [params.childId, lastRemoved]);

  useEffect(() => {
    entityManager.getItem();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastRemoved])

  const onGoBack = () => {
    let currentPathname = history.location.pathname
    history.push(currentPathname.substring(0, currentPathname.indexOf("riscos") - 1));
  }

  const entityManager = useEntityManager({
    repository: riscosRepository,
    relationKey: 'grupoExposicaoId',
    onGoBack
  });
  
  const schema = yup.object().shape({
    fatorRiscoId: yup.string().required('Obrigatório'),
    fonteGeradora: yup.string().required('Obrigatório'),
  })
  
  const validate = yupValidation(schema);

  const loadFontesGeradoras = async (query) => {
    
    const response = await fontesGeradorasRepository.list({
      paginate: false,
      query: {
        descricao: query,
        clienteId: params.clienteId
      }
    });

    if (response.ok) {
      return response.data.map(item => ({
        label: item.descricao,
        value: item.id
      }))
    } else {
      return []
    }
  }

  const loadMedidasControle = async (query) => {
    
    const response = await medidasControleRepository.list({
      paginate: false,
      query: {
        descricao: query,
        clienteId: params.clienteId
      }
    });

    if (response.ok) {
      return response.data.map(item => ({
        label: item.descricao,
        value: item.id
      }))
    } else {
      return []
    }
  }

  const autoSetTecnicaLT = (form, values, option) => {
    if (!values.ltLe) {
      form.mutators.setValue("ltLe", option.ltLe);
    }
    if (!values.tecnicaUtilizada) {
      form.mutators.setValue("tecnicaUtilizada", option.tecnicaUtilizada);
    }
    if (!values.nivelAcao) {
      form.mutators.setValue("nivelAcao", option.nivelAcao);
    }
  }

  return <CrudForm
    {...props}
    {...entityManager}
    withPaper
    validate={validate}
    formProps={{
      mutators: { setValue }
    }}
  >
    {({ form, values }) => {

      const isDirty = entityManager.isNew || form.getState().dirty;

      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} >
            <Field
              fullWidth
              name="fatorRiscoId"
              label="Fator de Risco"
              component={Autocomplete}
              options={fatoresRisco}
              onChange={(option) => autoSetTecnicaLT(form, values, option)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              fullWidth
              name="fonteGeradora"
              label="Fonte Geradora"
              component={AutocompleteCreatable}
              loadOptions={loadFontesGeradoras}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              fullWidth
              name="meioPropagacaoId"
              label="Exposição / Meio Propagação / Trajetória"
              component={Autocomplete}
              options={meiosPropagacao}
            />
          </Grid>
          <Grid item xs={12} sm={6} >
            <Field
              fullWidth
              name="transmitirESocial"
              component={Select}
              label="Transmitir E-Social?"
              options={[
                {
                  value: false,
                  label: 'NÃO'
                },
                {
                  value: true,
                  label: 'SIM'
                },
              ]}
            />
          </Grid>
          
          <Grid item xs={6} sm={3}>
            <Field
              fullWidth
              name="ltLe"
              label="LT / LE"
              component={TextField}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Field
              fullWidth
              name="nivelAcao"
              label="Nivel de Ação"
              component={TextField}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Field
              fullWidth
              name="tecnicaUtilizada"
              label="Técnica Utilizada"
              component={TextField}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Field
              fullWidth
              name="estrategiaAmostragem"
              label="Estratégia de Amostragem"
              component={Select}
              options={[
                {
                  value: 'PRELIMINAR',
                  label: 'Preliminar'
                },
                {
                  value: 'BASELINE',
                  label: 'Baseline'
                }
              ]}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Field
              fullWidth
              name="probabilidadeFrequencia"
              label="Probab. / Freq."
              component={TextField}
              type="number"
            />
            <span className={classes.observacao}>{"Deixar vazio quando N/A"}</span>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Field
              fullWidth
              name="consequenciaSeveridade"
              label="Conseq. / Severidade"
              component={TextField}
              type="number"
            />
            <span className={classes.observacao}>{"Deixar vazio quando N/A"}</span>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Field
              fullWidth
              name="grauRisco"
              label="Grau Risco"
              component={TextField}
              type="number"
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <Field
              fullWidth
              name="classeRiscoId"
              label="Classe de Risco"
              component={Select}
              options={classesRisco.map(item => ({
                value: item.value,
                label: <Typography style={{color: item.cor}}>{item.label}</Typography>
              }))}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="observacoes"
              label="Observações"
              component={TextField}
              multiline
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              fullWidth
              name="intensidadeConcentracao"
              label="Intens. / Conc. (MG)"
              component={TextField}
              disabled={detalhesAvaliacao?.length}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              fullWidth
              name="desvioPadrao"
              label="Desvio Padrão Geométrico (DPG)"
              component={TextField}
              disabled={detalhesAvaliacao?.length}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              fullWidth
              name="percentil"
              label="Percentil 95"
              component={TextField}
              disabled={detalhesAvaliacao?.length}
            />
          </Grid>
          <Grid item xs={12}>
            <span className={classes.label}>{"Detalhes Avaliações"}</span>
          </Grid>
          {isDirty && <Grid item xs={12} className={classes.mustSaveContainer}>
            <span className={classes.mustSaveText}>{"Para adicionar detalhes das avaliações, "}</span>
            <span className={classes.mustSaveLink} onClick={() => form.submit()}>{"salve as alterações"}</span>
          </Grid>} 
          {!isDirty && <Grid item xs={12} className={classes.mustSaveContainer}>
            <span className={classes.mustSaveText}>{"Ao adicionar detalhes de avaliação, o risco será quantitativo e os campos acima (MG, DPG, Perc. 95) serão calculados automaticamente."}</span>
          </Grid>}
          {!isDirty && detalhesAvaliacao?.length > 0 && <DetalhesAvaliacaoList detalhesAvaliacao={detalhesAvaliacao} lastRemoved={lastRemoved} setLastRemoved={setLastRemoved}/>}
          {!isDirty && <Grid item xs={12} className={classes.buttonAddContainer}>
            <Button onClick={() => history.push(`/clientes/${params.clienteId}/grupos-exposicao/${params.id}/riscos/${params.childId}/detalhes-avaliacao/new`)} variant='outlined' color='secondary'>
              {"Incluir avaliação"}
            </Button>
          </Grid>}
          <Grid item xs={12}>
            <span className={classes.label}>{"Medidas de Controle"}</span>
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="medidasControle"
              component={AutocompleteCreatable}
              isMulti
              loadOptions={loadMedidasControle}
            />
          </Grid>
          {isDirty && <Grid item xs={12} className={classes.mustSaveContainer}>
            <span className={classes.mustSaveText}>{"Para adicionar e editar planos de ação, "}</span>
            <span className={classes.mustSaveLink} onClick={() => form.submit()}>{"salve as alterações"}</span>
          </Grid>}
          {!isDirty && <PlanosAcaoRiscoList clienteId={params.clienteId} grupoExposicaoId={entityManager.id} riscoId={entityManager.childId}/>}
          
          <Grid item xs={12}>
            <Field
              fullWidth
              name="incluirLtcat"
              label="Incluir este risco no relatório LTCAT"
              type="checkbox"
              component={Checkbox}
              simple
            />
          </Grid>
          {values.incluirLtcat && <Grid item xs={12}>
            <Field
              fullWidth
              name="conclusaoLtcat"
              component={TextField}
              label="Conclusão para o agente nocivo LTCAT"
            />
          </Grid>}
          <Grid item xs={12}>
            <Field
              fullWidth
              name="incluirInsalubridade"
              label="Incluir este risco no relatório de Insalubridade"
              type="checkbox"
              component={Checkbox}
              simple
            />
          </Grid>
          {values.incluirInsalubridade && <Grid item xs={12}>
            <Field
              fullWidth
              name="conclusaoInsalubridade"
              component={TextField}
              label="Conclusão para o agente nocivo Insalubridade"
            />
          </Grid>}
        </Grid>
      )}}
  </CrudForm>
}

export default RiscosForm;
import { restApi } from '../../services/api'
import {createBasicRepository as createRepository} from '../../Components/Repository'
import { paginate } from '../../lib/feathers'

const dadosLtcatRepository = createRepository({
  path: 'dados-ltcat',
  restApi,
  queryTransform: {
    ...paginate
  },
})

export default dadosLtcatRepository

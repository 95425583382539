import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Field } from 'react-final-form'
import { Autocomplete, TextField } from '../../Components/Fields'
import { Checkbox, CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import dadosLtcatRepository from '../repositories/dadosLtcatRepository'
import DateField from '../../Components/Fields/DateField'
import trabalhadoresRepository from '../repositories/trabalhadoresRepository'
import { useHistory, useParams } from 'react-router-dom'

const DadosLtcatForm = props => {
  
  const params = useParams();
  const history = useHistory();
  
  const [trabalhadores, setTrabalhadores] = useState([]);

  useEffect(() => {
    ;(async () => {
      const response = await trabalhadoresRepository.list({
        query: {
          clienteId: params.clienteId
        },
        paginate: { [`$limit`]: 999 },
      });

      if (response.ok) {
        setTrabalhadores(response.data.map(item => ({
          label: item.nome,
          value: item.id
        })))
      }
    })()

  }, [params.clienteId]);

  const onGoBack = () => {
    let currentPathname = history.location.pathname
    history.push(currentPathname.substring(0, currentPathname.indexOf("dados-ltcat") - 1));
  }

  const entityManager = useEntityManager({
    repository: dadosLtcatRepository,
    handleError: data => data.message,
    goBackAfterSave: true,
    relationKey: 'riscoId',
    onGoBack
  });

  return (
    <>
      <CrudForm
        {...props}
        {...entityManager}
        withPaper
      >
        {({ values }) => <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="incluir"
              label="Incluir este risco no relatório LTCAT"
              type="checkbox"
              component={Checkbox}
              simple
            />
          </Grid>
          <Grid item xs={12} md={values.exibeAvaliacaoRuido ? 4 : 12}>
            <Field
              fullWidth
              name="exibeAvaliacaoRuido"
              label="Exibir avaliação ruído"
              type="checkbox"
              component={Checkbox}
              simple
            />
          </Grid>
          {values.exibeAvaliacaoRuido && <>
            <Grid item xs={12} md={4}>
              <Field
                fullWidth
                label="Data Dosimetria"
                component={DateField}
                name="dataDosimetria"
              />
            </Grid>
            <Grid item xs={12} md={4}>            
              <Field
                fullWidth
                name="trabalhadorId"
                label="Trabalhador Dosimetria"
                component={Autocomplete}
                options={trabalhadores}
              />
            </Grid>
            <Grid item xs={12} md={4}>   
              <Field
                fullWidth
                name="dose"
                label="Dose (%)"
                component={TextField}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={4}>   
              <Field
                fullWidth
                name="nen"
                label="NEN db(A)"
                component={TextField}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={4}>   
              <Field
                fullWidth
                name="histograma"
                label="Histograma"
                component={TextField}
              />
            </Grid>
          </>}
          
          <Grid item xs={12} md={4}>
            <Field
              fullWidth
              name="exibeAvaliacaoQuimica"
              label="Exibir avaliações químicas"
              type="checkbox"
              component={Checkbox}
              simple
            />
          </Grid>

          <Grid item xs={12}>
            <Field
              fullWidth
              name="conclusaoAgenteNocivo"
              component={TextField}
              label="Conclusão para o agente nocivo LTCAT"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              name="conclusaoInsalubridade"
              component={TextField}
              label="Conclusão para o agente nocivo Insalubridade"
            />
          </Grid>
        </Grid>}
      </CrudForm>
    </>
  )
}

export default DadosLtcatForm

import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { Field } from 'react-final-form'
import { AutocompleteCreatable, TextField } from '../../Components/Fields'
import planosAcaoRepository from '../repositories/planosAcaoRepository'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import planosAcaoRiscoRepository from '../repositories/planosAcaoRiscoRepository'
import { useHistory } from 'react-router'
import DateField from '../../Components/Fields/DateField'
import * as yup from 'yup'
import yupValidation from '../../lib/yupValidation'

const PlanoAcaoRiscoForm = (props) => {

  const [message, setMessage] = useState();


  const history = useHistory();
  
  const onGoBack = () => {
    let currentPathname = history.location.pathname
    history.push(currentPathname.substring(0, currentPathname.indexOf("planos-acao") - 1));
  }

  const entityManager = useEntityManager({
    repository: planosAcaoRiscoRepository,
    handleError: data => data.message,
    relationKey: 'riscoId',
    onGoBack
  });

  const loadOptions = async (query) => {
    
    const response = await planosAcaoRepository.list({
      paginate: false,
      query: {
        descricao: query
      }
    });

    if (response.ok) {
      return response.data.map(pa => ({
        label: pa.descricao,
        value: pa.id
      }))
    } else {
      return []
    }
  }

  const schema = yup.object().shape({
    planoAcao: yup.string().required('Obrigatório'),
  })
  
  const validate = yupValidation(schema);

  return <CrudForm
    {...props}
    {...entityManager}
    withPaper
    message={message}
    clearMessage={() => setMessage(undefined)}
    goBackOnSaveButton
    validate={validate}
  >
    {() => {
      return <Grid container spacing={3}>
        <Grid item xs={12}>
          <Field
            fullWidth
            name="planoAcao"
            component={AutocompleteCreatable}
            loadOptions={loadOptions}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            fullWidth
            name="responsavel"
            component={TextField}
            label="Responsável"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            fullWidth
            label="Data Prevista"
            component={DateField}
            name="dataPrevista"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Field
            fullWidth
            label="Data Realizada"
            component={DateField}
            name="dataRealizada"
          />
        </Grid>
      </Grid>
    }}
  </CrudForm>
}

export default PlanoAcaoRiscoForm;
import { restApi } from '../../services/api'
import { createBasicRepository as createRepository } from '../../Components/Repository'
import { paginate } from '../../lib/feathers'

const detalhesAvaliacaoRepository = createRepository({
  path: 'detalhes-avaliacao',
  restApi,
  queryTransform: {
    ...paginate
  },
})

export default detalhesAvaliacaoRepository

import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Field } from 'react-final-form'
import { TextField, Autocomplete } from '../../Components/Fields'
import detalhesAvaliacaoRepository from '../repositories/detalhesAvaliacaoRepository'
import { CrudForm } from '../../Components/Common'
import { useEntityManager } from '../../Components/Hooks'
import { useHistory, useParams } from 'react-router-dom'
import trabalhadoresRepository from '../repositories/trabalhadoresRepository'
import DateField from '../../Components/Fields/DateField'

const DetalhesAvaliacaoForm = (props) => {
  const history = useHistory();
  const params = useParams();

  const onGoBack = () => {
    let currentPathname = history.location.pathname
    history.push(currentPathname.substring(0, currentPathname.indexOf("detalhes-avaliacao") - 1));
  }

  const entityManager = useEntityManager({
    repository: detalhesAvaliacaoRepository,
    relationKey: 'riscoId',
    onGoBack,
    goBackAfterSave: true
  });

  const [trabalhadores, setTrabalhadores] = useState([]);

  useEffect(() => {
    ;(async () => {
      const response = await trabalhadoresRepository.list({
        query: {
          clienteId: params.clienteId
        },
        paginate: { [`$limit`]: 999 },
      });

      if (response.ok) {
        setTrabalhadores(response.data.map(item => ({
          label: item.nome,
          value: item.id
        })))
      }
    })()

  }, [params.clienteId]);

  return <CrudForm
    {...props}
    {...entityManager}
    withPaper
  >
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Field
          fullWidth
          label="Data"
          component={DateField}
          name="data"
        />
      </Grid>
      <Grid item xs={12} md={8}>            
        <Field
          fullWidth
          name="trabalhadorId"
          label="Trabalhador"
          component={Autocomplete}
          options={trabalhadores}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Field
          fullWidth
          name="intensidadeConcentracao"
          label="Intens. / Conc."
          component={TextField}
        />
      </Grid>
      <Grid item xs={12} md={4}>   
        <Field
          fullWidth
          name="histograma"
          label="Histograma / Relatório do Ensaio"
          component={TextField}
        />
      </Grid>
      <Grid item xs={12} md={4}>   
        <Field
          fullWidth
          name="tempoExposicao"
          label="Tempo de Exposição (min)"
          component={TextField}
        />
      </Grid>
    </Grid>
  </CrudForm>
}

export default DetalhesAvaliacaoForm;
import { restApi } from '../../services/api'
import {createBasicRepository as createRepository} from '../../Components/Repository'
import { paginate } from '../../lib/feathers'

const dadosLaudosGesRepository = createRepository({
  path: 'dados-laudos-ges',
  restApi,
  queryTransform: {
    ...paginate
  },
})

export default dadosLaudosGesRepository

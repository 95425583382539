import React, { useEffect, useState } from 'react'
import { useFilter, useListRepository } from '../../Components/Hooks'
import { Filter, List } from '../../Components/Common'
import { useParams, useHistory } from 'react-router-dom'
import trabalhadoresRepository from '../repositories/trabalhadoresRepository'
import { Button, makeStyles, Grid } from '@material-ui/core'
import TrabalhadoresImportacaoDialog from './TrabalhadoresImportacaoDialog'
import { Field } from 'react-final-form'
import { Autocomplete, TextField } from '../../Components/Fields'
import cargosRepository from '../repositories/cargosRepository'

const useStyles = makeStyles(theme => ({
  buttonRow: {
    marginTop: theme.spacing(2),
  },
}));

const TrabalhadoresFilter = () => {
  const filter = useFilter({});
  
  const params = useParams();
  const [cargos, setCargos] = useState();

  useEffect(() => {
    ;(async () => {
      const response = await cargosRepository.list({
        paginate: { [`$limit`]: 999 },
        query: { clienteId: params.id }
      });

      if (response.ok) {
        setCargos(response.data.map(item => ({
          label: item.nome,
          value: item.id
        })))
      }
    })()

  }, [params.id]);

  return (
    <Filter {...filter} labels={{ find: 'Buscar', clear: 'Limpar' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Field
            fullWidth
            name="matricula"
            label="Matricula"
            component={TextField}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            fullWidth
            name="nome"
            label="Nome"
            component={TextField}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            fullWidth
            name="cargoId"
            label="Cargo"
            component={Autocomplete}
            options={cargos}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

const TrabalhadoresList = props => {
 
  const params = useParams();
  const history = useHistory();

  const [flagDialogOpened, setFlagDialogOpened] = useState(false);

  const listHook = useListRepository({
    repository: trabalhadoresRepository,
    defaultSort: 'nome',
    path: `clientes/${params.id}/trabalhadores`,
    query: [
      ['clienteId', params.id]
    ]
  });

  const listOptions = {
    fields: {
      matricula: {
        label: 'Matrícula',
      },
      nome: {
        label: 'Nome',
      },
      cargo: {
        label: 'Cargo',
        format: (cargo) => cargo?.nome
      },
    }
  }

  const classes = useStyles();

  return (
    <>
        <TrabalhadoresFilter />
        <List
          {...props}
          {...listHook}
          removeItem={listHook.removeItem}
          onClickEdit={listHook.onClickEdit}
          onClickRow={listHook.onClickEdit}
          onClickView={null}
          onClickNew={null}
          hideDownloadIcon
          listOptions={listOptions}
          emptyText='Ainda não há trabalhadores associados a este cliente'
        />

        <Grid container spacing={2} direction="row-reverse" className={classes.buttonRow}>
          <Grid item xs={12} md={3}>
            <Button
              fullWidth
              onClick={listHook.onClickNew}
              variant='contained'
              color='primary'>
              Adicionar
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              fullWidth
              onClick={() => setFlagDialogOpened(!flagDialogOpened)}
              variant='contained'
              color='secondary'>
              Importar Via Planilha
            </Button>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              fullWidth
              onClick={() => {
                let currentPathname = history.location.pathname
                history.push(currentPathname.substring(0, currentPathname.indexOf("trabalhadores") - 1))
              }}
              variant='outlined'
              color='secondary'>
              {"<< Voltar"}
            </Button>
          </Grid>
        </Grid>

      <TrabalhadoresImportacaoDialog
        clienteId={params.id}
        open={!!flagDialogOpened}
        handleClose={() => { 
          setFlagDialogOpened(false);
          listHook.fetchList()
        }}
     />


    </>
  )
}

export default TrabalhadoresList

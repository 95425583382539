import React from 'react'
import { Route, BrowserRouter, Switch } from 'react-router-dom'
import { Login, RecoverPassword, ResetPassword } from '../modules/login'
import { AppLoggedRouter } from './AppLoggedRouter'
import { PrivateRoute, loginRoutes } from "./../Components/Login"

export default function AppGlobalRouter() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path={loginRoutes.login} component={Login} />
                <Route
                    path={loginRoutes.recoverPassword}
                    component={RecoverPassword}
                />
                <Route
                    path='/init'
                    component={ResetPassword}
                />
                <PrivateRoute path="/" component={AppLoggedRouter} />
            </Switch>
        </BrowserRouter>
    )
}

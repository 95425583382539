import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import AddImageInput from '../../Components/Common/AddImageInput'
import noImage from '../../img/no_logo.png'
import fotosLocalTrabalhoRepository from '../repositories/fotosLocalTrabalhoRepository'
import RemovableImage from '../../Components/Common/RemovableImage'

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    paddingTop: theme.spacing(2)
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1)
  },
  imageContainer: {
    padding: theme.spacing(1)
  }
}))

const FotosLocalTrabalho = ({ localTrabalhoId, setMessage }) => {
  
  const [fotos, setFotos] = useState([]);
  const [version, setVersion] = useState(0);

  useEffect(() => {
    ;(async () => {
      const response = await fotosLocalTrabalhoRepository.list({ 
        query: {
          localTrabalhoId
        }
      });

      if (response.ok) {
        setFotos(response.data)
      }
    })()

  }, [localTrabalhoId, version]);

  const onClickDelete = (id) => {
    fotosLocalTrabalhoRepository.remove(id).then(response => {
      if (response.ok) {
        setVersion(version + 1)
      } else {
        setMessage("Erro ao remover imagem!")
      }
    })
  }

  const onAddFoto = (data) => {
    if (data && data.length > 0) {
      const file = data[0].file;
      fotosLocalTrabalhoRepository.create({ foto: file, localTrabalhoId }).then(response => {
        if (response.ok) {
          setVersion(version + 1);
        } else {
          setMessage("Erro ao adicionar imagem!")
        }
      });
    }
  }

  const classes = useStyles();

  return <Grid container>
    <Grid item xs={12}>
      <span className={classes.label}>{"Fotos"}</span>
    </Grid>
    {fotos.map(f => <Grid item key={f.id} className={classes.imageContainer}>
      <RemovableImage size={200} src={f.fotoUrl} onClickDelete={() => onClickDelete(f)} />
    </Grid>)}
    <Grid item>
      <AddImageInput onChange={onAddFoto} size={200} backgroundImage={noImage} />
    </Grid>
  </Grid>
}

export default FotosLocalTrabalho;
import React from 'react'
import { useFilter, useListRepository } from '../../Components/Hooks'
import { Filter, List } from '../../Components/Common'
import { useParams, useHistory } from 'react-router-dom'
import gruposExposicaoRepository from '../repositories/gruposExposicaoRepository'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { Field } from 'react-final-form'
import { TextField } from '../../Components/Fields'

const useStyles = makeStyles(theme => ({
  buttonRow: {
    marginTop: theme.spacing(2),
    alignItems: 'center'
  },
  mustSaveText: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    marginLeft: theme.spacing(2)
  },
  mustSaveLink: {
    marginLeft: 4,
    fontSize: 14,
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
}));


const GruposExposicaoFilter = () => {
  const filter = useFilter({})
  return (
    <Filter {...filter} labels={{ find: 'Buscar', clear: 'Limpar' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Field
            fullWidth
            name="codigo"
            label="Código"
            component={TextField}
          />
        </Grid> 
        <Grid item xs={12} sm={8}>
          <Field
            fullWidth
            name="descricao"
            label="Descrição"
            component={TextField}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}


const GruposExposicaoList = props => {
 
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();

  const listHook = useListRepository({
    repository: gruposExposicaoRepository,
    defaultSort: 'codigo',
    path: `clientes/${params.id}/grupos-exposicao`,
    query: [
      ['clienteId', params.id]
    ]
  });

  const listOptions = {
    fields: {
      codigo: {
        label: 'Código',
      },
      descricao: {
        label: 'Descrição',
      },
    }
  }

  return (
    <>
      <GruposExposicaoFilter/>
      <List
        {...props}
        {...listHook}
        removeItem={listHook.removeItem}
        onClickEdit={listHook.onClickEdit}
        onClickRow={listHook.onClickEdit}
        onClickView={null}
        onClickNew={null}
        hideDownloadIcon
        listOptions={listOptions}
        emptyText='Ainda não há GES associados a este cliente'
      />
      <Grid container spacing={2} direction="row-reverse" className={classes.buttonRow}>
        <Grid item xs={12} md={3}>
          <Button
            fullWidth
            onClick={listHook.onClickNew}
            variant='contained'
            color='primary'>
            Adicionar
          </Button>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            fullWidth
            onClick={() => {
              let currentPathname = history.location.pathname
              history.push(currentPathname.substring(0, currentPathname.indexOf("grupos-exposicao") - 1))
            }}
            variant='outlined'
            color='secondary'>
            {"<< Voltar"}
          </Button>
        </Grid>

        <Grid xs={12} md={6}>
          <span className={classes.mustSaveText}>{"Para facilitar a criação de GES, use a "}</span>
          <span className={classes.mustSaveLink} onClick={() => {
                let currentPathname = history.location.pathname
                history.push(currentPathname.substring(0, currentPathname.indexOf("grupos-exposicao")) + 'trabalhadores')
          }}>{"importação de trabalhadores"}</span>
        </Grid>
      </Grid>
    </>
  )
}

export default GruposExposicaoList
